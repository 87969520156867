import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/800.css'
import '@glidejs/glide/dist/css/glide.core.min.css'
import 'aos/dist/aos.css'
import '../css/base.css'

import Alpine from 'alpinejs'
import Aos from 'aos'
import Glide from '@glidejs/glide'

import timer from './timer.js'

window.Alpine = Alpine
Alpine.data('timer', timer)
Alpine.start()

// Aos will use DOMContentLoaded by default: https://github.com/michalsnik/aos/tree/v2#additional-configuration
Aos.init({ once: true })

let lastKnownScrollPosition = 0
let ticking = false
const setBodyBg = () => {
  if (lastKnownScrollPosition < 1000) {
    document.body.style.backgroundColor = '#e81c25'
  } else {
    document.body.style.backgroundColor = '#282828'
  }
}
window.addEventListener('scroll', () => {
  lastKnownScrollPosition = window.scrollY
  if (!ticking) {
    window.requestAnimationFrame(() => {
      setBodyBg(lastKnownScrollPosition)
      ticking = false
    })

    ticking = true
  }
})

window.addEventListener('load', () => {
  // not on DOMContentLoaded, as we need the css to be there
  document.querySelectorAll('.glide').forEach(glide => {
    new Glide(glide, {
      bound: true,
      gap: 0,
      perView: 4,
      breakpoints: {
        767: {
          perView: 2,
        },
        1023: {
          perView: 3,
        },
      },
    }).mount()
  })
})

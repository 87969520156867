export default () => ({
  refreshTimer() {
    const rootEl = this.$root
    const timestampStr = rootEl.dataset.timestamp
    const timestamp = new Date(timestampStr)
    const now = new Date().getTime()
    const distance = timestamp - now

    if (distance > 0) {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

      rootEl.querySelector('[data-timer-label="days"]').innerHTML = `${days}d`
      rootEl.querySelector('[data-timer-label="hours"]').innerHTML = `${hours}h`
      rootEl.querySelector('[data-timer-label="minutes"]').innerHTML = `${minutes}m`
    } else {
      clearInterval(this.interval)
      rootEl.innerHTML = 'Applications are now closed.'
    }
  },
  init() {
    this.interval = setInterval(this.refreshTimer.bind(this), 1000)
    this.refreshTimer()
  },
})
